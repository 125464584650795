import {createSlice} from '@reduxjs/toolkit';
import {handleAsyncRequest} from '../utils';
import {getRequest} from '@/shared/utils/requests';

const initialState = {
  error: null,
  isLoading: false,
  list: [],
  currentData: [],
};

export const slice = createSlice({
  name: 'feedbacks',
  initialState,
  reducers: {
    hasError: (state, action) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setFeedbacksList: (state, action) => {
      state.list = action.payload;
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
  },
});

export default slice.reducer;
const actions = slice.actions;

// Export actions
export const {hasError, startLoading, stopLoading, setCurrentData} = actions;

// Selectors
export const getAllFeedbacks = (state) => state.feedbacks.list;

export const getCurrentData = (state) => state.feedbacks.currentData;

// Fetch Feedbacks
export const fetchFeedbacks = () => async (dispatch) => {
  const {error, body} = await handleAsyncRequest({
    dispatch,
    actions,
    requestFn: getRequest,
    endpoint: `/feedbacks`,
    toastMessage: {success: {show: false}, error: {show: true}},
  });

  if (error) throw error;
  else dispatch(actions.setFeedbacksList(body?.feedbacks));
};
